

const LaravelJson = 
  
  [
  
  
    {
        "id": 1,
        "html_title": "intro",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "What is Laravel?",
            "bootstrap_title_desc": "Laravel is a powerful, open-source PHP web framework designed to simplify the development process by providing a robust foundation for building modern web applications. It offers a clean, expressive syntax and a rich ecosystem of tools that streamline common tasks like routing, authentication, database interaction, and more."
          },
          {
            "bootstrap_title_heading": " Prerequisites?",
            "bootstrap_title_desc": `Before starting Laravel, ensure you have basic knowledge of:

HTML/CSS/JavaScript: For the front end.
PHP: Understanding PHP basics is essential.
SQL: Familiarity with database operations like SELECT, INSERT, UPDATE, and DELETE.`
          },

          {
            "bootstrap_title_heading": "Set Up Your Environment",
            "bootstrap_title_desc": `Install PHP (version 8.0 or higher is recommended):
Download from php.net.

Install Composer:

Composer is a dependency manager for PHP. Download it from getcomposer.org.

Install a Local Server:

Use tools like XAMPP, WAMP, or Laragon, or use Docker for advanced setups.

Install a Local Server:

Use tools like XAMPP, WAMP, or Laragon,  Laravel Herd or use Docker for advanced setups.


herd --version
php --version
laravel --version
composer --version


-create one folder in Herd

start the server by using -php artisan serve
`
          },
       

        
        ],
        "coursename": "Laravel Intro (day-1)"
      },

      {
        "id": 2,
        "html_title": "laravel-file structure",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "Understand the Folder Structure",
            "bootstrap_title_desc": `app/: Contains the core application logic.
routes/: Contains the route definitions (e.g., web.php for web routes).
resources/views/: Contains the Blade templates for the views.
public/: Contains assets like images, CSS, and JavaScript files.
.env: Configuration file for database, environment variables, etc.




app/

Contains the core application logic, including:
Controllers (app/Http/Controllers)
Middleware
Models
Required: You can't delete this folder because it holds the main logic of your application.



routes/

Contains the route definitions, like web.php (for web routes) and api.php (for API routes).
Required: Laravel won't know where to direct requests without this folder.




resources/views/

Contains the Blade templates for your frontend views.
Optional: If you're not using Blade templates (e.g., using React or Vue), you can avoid using this folder, but deleting it isn't recommended.




public/

Contains public-facing files like:
index.php (entry point for the application)
Images, CSS, JavaScript, etc.
Required: Deleting this folder will break your app because index.php is the main entry point.



.env

Configuration file for environment variables (e.g., database connection, app URL, etc.).
Required: Laravel relies heavily on .env for settings. Deleting this file will lead to configuration errors.



database/

Contains migration files for managing your database schema, along with seeds and factories.
Required if you're using Laravel's database features like migrations.



storage/

Stores:
Logs (logs/)
Cached views (framework/views/)
Uploaded files (app/public/)
Required: Many Laravel features depend on this folder, such as file uploads and error logs.




bootstrap/

Contains the cache/ folder for optimizing the application and autoloading settings.
Required: Deleting it will break the application bootstrapping process.



vendor/

Contains all Composer dependencies and Laravel's core framework files.
Required: Deleting this folder will break the framework entirely.



config/

Contains configuration files for the framework (e.g., database, mail, session).
Required: Laravel uses these files to manage its internal settings.




storage/framework/

Stores compiled views, sessions, and other temporary files.
Required: Necessary for caching and optimizing the application.`
          },
         
       

        
        ],
        "coursename": "File Structure (day-2)"
      },


      {
        "id": 3,
        "html_title": "laravel-hellow-world",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "web.php",
            "bootstrap_title_desc": `Route::get('/', function () {
    return 'Hello, Laravel!';
});`
          },

          {
            "bootstrap_title_heading": "run file",
            "bootstrap_title_desc": `Route::get('/about', function () {
    return view('about');
});


Generate a Controller: Run the following command in your terminal to generate a controller:

php artisan make:controller HomeController


Update the Route: Replace the inline closure with the controller method.

Route::get('/', [App\Http\Controllers\HomeController::class, 'index']);


app/Http/Controllers/HomeController.php

<?php

namespace App\Http\Controllers;

use Illuminate\Http\Request;

class HomeController extends Controller
{
    public function index()
    {
        return view('welcome'); 
    }
}


welcome.blade.php


<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
<h1>Welcome</h1>

</body>
</html>

`
          },


       

        
        ],
        "coursename": "Hello World (day-3)"
      },

      {
        "id": 4,
        "html_title": "laravel-external-css",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "app.css",
            "bootstrap_title_desc": `public/css/app.css`
          },

          {
            "bootstrap_title_heading": "welcome.blade.php",
            "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="{{ asset('/css/app.css') }}" rel="stylesheet">
    <title>Document</title>
</head>
<body>

    <h1>This is a Heading
    kvmkfmvkfmv
    </h1> 
</body>
</html>
`
          },

          {
            "bootstrap_title_heading": "resources/views/includes/header.blade.php",
            "bootstrap_title_desc": `<header style="background-color: #f8f9fa; padding: 10px; text-align: center; border-bottom: 1px solid #ddd;">
    <h1>My Website</h1>
    <nav>
        <a href="/" style="margin-right: 15px; text-decoration: none;">Home</a>
        <a href="/about" style="margin-right: 15px; text-decoration: none;">About</a>
        <a href="/contact" style="text-decoration: none;">Contact</a>
    </nav>
</header>
`
          },

          {
            "bootstrap_title_heading": "resources/views/includes/footer.blade.php",
            "bootstrap_title_desc": `<footer style="background-color: #f8f9fa; padding: 10px; text-align: center; border-top: 1px solid #ddd; position: fixed; bottom: 0; width: 100%;">
    <p>&copy; {{ date('Y') }} My Website. All rights reserved.</p>
</footer>
`
          },


          {
            "bootstrap_title_heading": "routes/web.php",
            "bootstrap_title_desc": `Route::get('/', function () {
    return view('home');
});

`
          },

          {
            "bootstrap_title_heading": "resources/views/home.blade.php",
            "bootstrap_title_desc": `@extends('layouts.app')

@section('title', 'Home Page')

@section('content')
    <h2>Welcome to My Website!</h2>
    <p>This is the home page content. You can add more sections or information here.</p>
@endsection


`
          },

          {
            "bootstrap_title_heading": "resources/views/layouts/app.blade.php",
            "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="stylesheet" href="{{ asset('/css/app.css') }}">
    <title>@yield('title', 'My Website')</title>
</head>
<body>
    @include('includes.header')

    <main style="padding: 20px;">
        @yield('content')
    </main>

    @include('includes.footer')
</body>
</html>
`
          },

        


       

        
        ],
        "coursename": "Css (day-4)"
      },


      {
        "id": 4,
        "html_title": "laravel-link-file",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "resources/views/layouts/app.blade.php",
            "bootstrap_title_desc": `<header>
    <nav>
        <ul>
            <li><a href="{{ route('home') }}">Home</a></li>
            <li><a href="{{ route('about') }}">About</a></li>
            <li><a href="{{ route('contact') }}">Contact</a></li>
        </ul>
    </nav>
</header>
`
          },

          {
            "bootstrap_title_heading": "Define Routes in web.php",
            "bootstrap_title_desc": `Route::get('/', function () {
    return view('home');
})->name('home');

Route::get('/about', function () {
    return view('about');
})->name('about');

Route::get('/contact', function () {
    return view('contact');
})->name('contact');

`
          },

       


        

        


          {
            "bootstrap_title_heading": "app.blade.php",
            "bootstrap_title_desc": `<header>
    <nav>
        <ul>
            <li><a href="{{ route('home') }}">Home</a></li>
            <li><a href="{{ route('about') }}">About</a></li>
            <li><a href="{{ route('contact') }}">Contact</a></li>
        </ul>
    </nav>

    <!-- Button to open the modal -->
    <button onclick="openModal()" class="btn">Open Modal</button>
</header>

<!-- Modal Structure -->
<div id="myModal" class="modal">
    <div class="modal-content">
        <span onclick="closeModal()" class="close">&times;</span>
        <h2>Modal Title</h2>
        <p>This is the content of the modal.</p>
        <button onclick="closeModal()" class="btn">Close</button>
    </div>
</div>

<!-- Link to the external JS file -->
<script src="{{ asset('js/app.js') }}"></script>
`
          },



          {
            "bootstrap_title_heading": "public/js/app.js",
            "bootstrap_title_desc": `// Open the modal
function openModal() {
    const modal = document.getElementById('myModal');
    modal.style.display = 'block';
}

// Close the modal
function closeModal() {
    const modal = document.getElementById('myModal');
    modal.style.display = 'none';
}

// Close the modal if the user clicks outside of it
window.onclick = function (event) {
    const modal = document.getElementById('myModal');
    if (event.target === modal) {
        modal.style.display = 'none';
    }
};
`
          },
       

        


       

        
        ],
        "coursename": "Link File (day-5)"
      },


      {
        "id": 4,
        "html_title": "login-form",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "Updated Login Form",
            "bootstrap_title_desc": `<header>
    <nav>
        <ul>
            <li><a href="{{ route('home') }}">Home</a></li>
            <li><a href="{{ route('about') }}">About</a></li>
            <li><a href="{{ route('contact') }}">Contact</a></li>
        </ul>
    </nav>

   
    <button onclick="openLoginModal()" class="btn">Login</button>
</header>

<!-- Login Modal -->
<div id="loginModal" class="modal">
    <div class="modal-content">
        <span onclick="closeLoginModal()" class="close">&times;</span>
        <h2>Login</h2>
        <form id="loginForm">
            <div>
                <label for="email">Email:</label>
                <input type="email" id="email" name="email" required>
            </div>
            <div>
                <label for="password">Password:</label>
                <input type="password" id="password" name="password" required>
            </div>
            <button type="submit" class="btn">Submit</button>
        </form>
    </div>
</div>

<!-- Link to the external JS file -->
<script src="{{ asset('js/app.js') }}"></script>
`
          },

          {
            "bootstrap_title_heading": "public/js/app.js",
            "bootstrap_title_desc": `document.getElementById('loginForm').addEventListener('submit', async function (event) {
    event.preventDefault(); // Prevent page refresh

    // Get form data
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;

    // Send form data via POST request
    try {
        const response = await fetch('/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content, // CSRF Token
            },
            body: JSON.stringify({ email, password }),
        });

        const result = await response.json();

        if (response.ok) {
            console.log('Login successful:', result);
            alert('Login successful!');
            closeLoginModal(); // Optionally close the modal
        } else {
            console.error('Login failed:', result);
            alert('Login failed: ' + result.message);
        }
    } catch (error) {
        console.error('Error:', error);
        alert('An error occurred.');
    }
});

<meta name="csrf-token" content="{{ csrf_token() }}">
Laravel requires a CSRF token for POST requests. Add this to your Blade view in the <head> section
`
          },

          {
            "bootstrap_title_heading": "routes/web.php",
            "bootstrap_title_desc": `use Illuminate\Http\Request;

Route::post('/login', function (Request $request) {
    $email = $request->input('email');
    $password = $request->input('password');

    // Simulate login logic (replace with actual authentication)
    if ($email === 'test@example.com' && $password === 'password') {
        return response()->json(['message' => 'Login successful'], 200);
    } else {
        return response()->json(['message' => 'Invalid credentials'], 401);
    }
});
`
          },

        ],
        "coursename": "Login Form (day-6)"
      },


      {
        "id": 5,
        "html_title": "contact-form",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "Create a Controller",
            "bootstrap_title_desc": `php artisan make:controller ContactController
`
          },

          {
            "bootstrap_title_heading": "routes/web.php",
            "bootstrap_title_desc": `use App\Http\Controllers\ContactController;

Route::get('/contact', [ContactController::class, 'show'])->name('contact.show');
Route::post('/contact', [ContactController::class, 'submit'])->name('contact.submit');
`
          },


          {
            "bootstrap_title_heading": "ContactController.php",
            "bootstrap_title_desc": `namespace App\Http\Controllers;

use Illuminate\Http\Request;
use Illuminate\Support\Facades\Validator;

class ContactController extends Controller
{
    public function show()
    {
        return view('contact');
    }

    public function submit(Request $request)
    {
        $validator = Validator::make($request->all(), [
            'name' => 'required|max:255',
            'email' => 'required|email',
            'message' => 'required|min:10',
        ]);

        if ($validator->fails()) {
            return redirect()->back()
                ->withErrors($validator)
                ->withInput();
        }

        // Handle the data (e.g., save to database, send email)
        return redirect()->route('contact.show')->with('success', 'Message sent successfully!');
    }
}
`
          },

          {
            "bootstrap_title_heading": "contact.blade.php",
            "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Contact Us</title>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css">
</head>
<body>
<div class="container mt-5">
    <h1>Contact Us</h1>

    @if(session('success'))
        <div class="alert alert-success">
            {{ session('success') }}
        </div>
    @endif

    @if($errors->any())
        <div class="alert alert-danger">
            <ul>
                @foreach($errors->all() as $error)
                    <li>{{ $error }}</li>
                @endforeach
            </ul>
        </div>
    @endif

    <form action="{{ route('contact.submit') }}" method="POST">
        @csrf
        <div class="mb-3">
            <label for="name" class="form-label">Name</label>
            <input type="text" name="name" id="name" class="form-control" value="{{ old('name') }}" required>
        </div>

        <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input type="email" name="email" id="email" class="form-control" value="{{ old('email') }}" required>
        </div>

        <div class="mb-3">
            <label for="message" class="form-label">Message</label>
            <textarea name="message" id="message" class="form-control" rows="5" required>{{ old('message') }}</textarea>
        </div>

        <button type="submit" class="btn btn-primary">Send</button>
    </form>
</div>
</body>
</html>
`
          },
       
          {
            "bootstrap_title_heading": "Edit the .env",
            "bootstrap_title_desc": `MAIL_MAILER=smtp
MAIL_HOST=smtp.example.com
MAIL_PORT=587
MAIL_USERNAME=your_email@example.com
MAIL_PASSWORD=your_email_password
MAIL_ENCRYPTION=tls
MAIL_FROM_ADDRESS=your_email@example.com
MAIL_FROM_NAME="Your Application Name"
`
          },

          {
            "bootstrap_title_heading": "Edit the .env",
            "bootstrap_title_desc": `MAIL_MAILER=smtp
MAIL_HOST=smtp.example.com
MAIL_PORT=587
MAIL_USERNAME=your_email@example.com
MAIL_PASSWORD=your_email_password
MAIL_ENCRYPTION=tls
MAIL_FROM_ADDRESS=your_email@example.com
MAIL_FROM_NAME="Your Application Name"
`
          },

          {
            "bootstrap_title_heading": "Create a Mailable Class",
            "bootstrap_title_desc": `php artisan make:mail ContactMail`
          },

          {
            "bootstrap_title_heading": "app/Mail/ContactMail.php",
            "bootstrap_title_desc": `namespace App\Mail;

use Illuminate\Bus\Queueable;
use Illuminate\Mail\Mailable;
use Illuminate\Queue\SerializesModels;

class ContactMail extends Mailable
{
    use Queueable, SerializesModels;

    public $data;

    /**
     * Create a new message instance.
     *
     * @param array $data
     */
    public function __construct($data)
    {
        $this->data = $data;
    }

    /**
     * Build the message.
     *
     * @return $this
     */
    public function build()
    {
        return $this->subject('Contact Form Submission')
                    ->view('emails.contact')
                    ->with('data', $this->data);
    }
}
`
          },

          {
            "bootstrap_title_heading": "resources/views/emails/contact.blade.php",
            "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Contact Form Submission</title>
</head>
<body>
    <h1>Contact Form Submission</h1>
    <p><strong>Name:</strong> {{ $data['name'] }}</p>
    <p><strong>Email:</strong> {{ $data['email'] }}</p>
    <p><strong>Message:</strong></p>
    <p>{{ $data['message'] }}</p>
</body>
</html>
`
          },

          {
            "bootstrap_title_heading": "Update the Controller",
            "bootstrap_title_desc": `use Illuminate\Support\Facades\Mail;
use App\Mail\ContactMail;

public function submit(Request $request)
{
    $validated = $request->validate([
        'name' => 'required|max:255',
        'email' => 'required|email',
        'message' => 'required|min:10',
    ]);

    // Send the email
    Mail::to($validated['email'])->send(new ContactMail($validated));

    return redirect()->route('contact.show')->with('success', 'Message sent successfully!');
}
`
          },

        ],
        "coursename": "Contact Form (day-7)"
      },

      {
        "id": 6,
        "html_title": "deploye-laravel",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "way-1",
            "bootstrap_title_desc": `Install Laravel Vite Plugin: If you want to serve the Laravel frontend via Vercel:

composer require innocenzi/laravel-vite
`
          },

          {
            "bootstrap_title_heading": "way-2",
            "bootstrap_title_desc": `Deploy on Render
Render offers free hosting for static sites, APIs, and even database support.

Steps:
Prepare Environment:

Add your .env file or configure environment variables in Render’s dashboard.
Ensure you’re using a database like SQLite or connect a free tier DB (e.g., PostgreSQL).
Push to GitHub: Push your Laravel project to a GitHub repository.

Connect to Render:

Go to Render.
Click New Web Service.
Select your GitHub repository.
Configure the build command:

composer install
php artisan migrate --force
php artisan config:cache
Set your STARTUP COMMAND to:

php artisan serve --host 0.0.0.0 --port $PORT
Deploy: Render will automatically deploy your app.
`
          },

        

      

        ],
        "coursename": "Deploye (day-8)"
      },

      {
        "id": 7,
        "html_title": "dynamic-page",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "Create Database and Configure",
            "bootstrap_title_desc": `Create a database (e.g., categories_products).
Update .env file with database credentials:`
          },

          {
            "bootstrap_title_heading": ".env",
            "bootstrap_title_desc": `DB_DATABASE=categories_products
DB_USERNAME=root
DB_PASSWORD=your_password
`
          },

          {
            "bootstrap_title_heading": "Create Models and Migrations",
            "bootstrap_title_desc": `php artisan make:model Category -m
php artisan make:model Product -m

-Define Database Tables
Open the migration files in database/migrations/ and define the tables


`
          },

          {
            "bootstrap_title_heading": "Categories Table",
            "bootstrap_title_desc": `public function up()
{
    Schema::create('categories', function (Blueprint $table) {
        $table->id();
        $table->string('name');
        $table->timestamps();
    });
}
`
          },

          {
            "bootstrap_title_heading": "Products Table",
            "bootstrap_title_desc": `public function up()
{
    Schema::create('products', function (Blueprint $table) {
        $table->id();
        $table->string('name');
        $table->decimal('price', 8, 2);
        $table->foreignId('category_id')->constrained()->onDelete('cascade');
        $table->timestamps();
    });
}


run:php artisan migrate
`
          },

          {
            "bootstrap_title_heading": "Add Sample Data",
            "bootstrap_title_desc": `php artisan make:seeder CategorySeeder
php artisan make:seeder ProductSeeder

database/seeders/CategorySeeder.php

public function run()
{
    \App\Models\Category::create(['name' => 'Electronics']);
    \App\Models\Category::create(['name' => 'Books']);
}


database/seeders/ProductSeeder.php

public function run()
{
    \App\Models\Product::create(['name' => 'Laptop', 'price' => 1000, 'category_id' => 1]);
    \App\Models\Product::create(['name' => 'Smartphone', 'price' => 500, 'category_id' => 1]);
    \App\Models\Product::create(['name' => 'Fiction Book', 'price' => 20, 'category_id' => 2]);
    \App\Models\Product::create(['name' => 'Science Book', 'price' => 30, 'category_id' => 2]);
}

Run the seeders:
php artisan db:seed --class=CategorySeeder
php artisan db:seed --class=ProductSeeder


`
          },

          {
            "bootstrap_title_heading": "Create Routes",
            "bootstrap_title_desc": `use App\Http\Controllers\CategoryController;
use App\Http\Controllers\ProductController;

Route::get('/', [CategoryController::class, 'index']);
Route::get('/category/{id}', [ProductController::class, 'index']);

Create Controllers

php artisan make:controller CategoryController
php artisan make:controller ProductController

app/Http/Controllers/CategoryController.php

namespace App\Http\Controllers;

use App\Models\Category;

class CategoryController extends Controller
{
    public function index()
    {
        $categories = Category::all();
        return view('categories', compact('categories'));
    }
}

app/Http/Controllers/ProductController.php

namespace App\Http\Controllers;

use App\Models\Product;

class ProductController extends Controller
{
    public function index($id)
    {
        $products = Product::where('category_id', $id)->get();
        return view('products', compact('products'));
    }
}

create views 

resources/views/categories.blade.php

<!DOCTYPE html>
<html>
<head>
    <title>Categories</title>
</head>
<body>
    <h1>Categories</h1>
    <ul>
        @foreach ($categories as $category)
            <li><a href="/category/{{ $category->id }}">{{ $category->name }}</a></li>
        @endforeach
    </ul>
</body>
</html>

resources/views/products.blade.php

<!DOCTYPE html>
<html>
<head>
    <title>Products</title>
</head>
<body>
    <h1>Products</h1>
    <ul>
        @foreach ($products as $product)
            <li>{{ $product->name }} - '$'{{ $product->price }}</li>
        @endforeach
    </ul>
    <a href="/">Back to Categories</a>
</body>
</html>


`
          },
       

        ],
        "coursename": "Dynamic Page (day-9)"
      },

      {
        "id": 8,
        "html_title": "e-commerce-authentication",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "Install Laravel Breeze",
            "bootstrap_title_desc": `Laravel Breeze is a simple implementation for authentication scaffolding. Install it using the following commands:
      
      1. Add Breeze to your project:
         composer require laravel/breeze --dev
      
      2. Install the scaffolding:
         php artisan breeze:install
      
      3. Compile assets:
         npm install && npm run dev
      
      4. Run migrations:
         php artisan migrate
      
      After running these commands, your project will have a basic authentication system with login, registration, and password reset functionality.`
          },
          {
            "bootstrap_title_heading": "Customize Auth Views",
            "bootstrap_title_desc": `The default authentication views are located in resources/views/auth. If you want to customize them, you can edit the following files:
      
      1. **Login View**: resources/views/auth/login.blade.php
      2. **Register View**: resources/views/auth/register.blade.php
      3. **Forgot Password View**: resources/views/auth/forgot-password.blade.php
      4. **Reset Password View**: resources/views/auth/reset-password.blade.php
      
      For example, to customize the login view, open login.blade.php and modify the HTML and Blade syntax as needed.`
          },
          {
            "bootstrap_title_heading": "Protect Routes",
            "bootstrap_title_desc": `To restrict access to certain routes, you can use Laravel's auth middleware. Here's an example of applying middleware to your routes:
      
      1. Open the routes/web.php file.
      2. Wrap the routes you want to protect with the auth middleware:
      
         Route::middleware(['auth'])->group(function () {
             Route::get('/dashboard', function () {
                 return view('dashboard');
             })->name('dashboard');
      
             Route::get('/cart', [ShoppingCartController::class, 'index']);
         });
      
      3. Add a route for the home page that doesn't require authentication:
         Route::get('/', function () {
             return view('welcome');
         });`
          },
          {
            "bootstrap_title_heading": "Redirect After Login",
            "bootstrap_title_desc": `After a user logs in, you might want to redirect them to a specific page. To configure this, update the authenticated() method in the app/Http/Controllers/Auth/LoginController.php file:
      
         protected function authenticated(Request $request, $user)
         {
             return redirect('/dashboard');
         }
      
      Alternatively, you can modify the HOME constant in the RouteServiceProvider:
         const HOME = '/dashboard';`
          }
        ],
        "coursename": "E-Commerce Authentication (day-10)"
      },
      {
        "id": 9,
        "html_title": "e-commerce-auth-pages",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "Create Login Page",
            "bootstrap_title_desc": `Customize the default login page located at resources/views/auth/login.blade.php:
      
         <!DOCTYPE html>
         <html>
         <head>
             <title>Login</title>
             <link rel="stylesheet" href="{{ asset('css/app.css') }}">
         </head>
         <body>
             <div class="container">
                 <h1>Login</h1>
                 @if (session('status'))
                     <div class="alert alert-success">
                         {{ session('status') }}
                     </div>
                 @endif
                 <form method="POST" action="{{ route('login') }}">
                     @csrf
                     <div>
                         <label for="email">Email</label>
                         <input type="email" id="email" name="email" value="{{ old('email') }}" required autofocus>
                         @error('email') <span>{{ $message }}</span> @enderror
                     </div>
                     <div>
                         <label for="password">Password</label>
                         <input type="password" id="password" name="password" required>
                         @error('password') <span>{{ $message }}</span> @enderror
                     </div>
                     <button type="submit">Login</button>
                     <a href="{{ route('password.request') }}">Forgot Password?</a>
                 </form>
                 <p>Don't have an account? <a href="{{ route('register') }}">Register</a></p>
             </div>
         </body>
         </html>`
          },
          {
            "bootstrap_title_heading": "Create Register Page",
            "bootstrap_title_desc": `Customize the default register page at resources/views/auth/register.blade.php:
      
         <!DOCTYPE html>
         <html>
         <head>
             <title>Register</title>
             <link rel="stylesheet" href="{{ asset('css/app.css') }}">
         </head>
         <body>
             <div class="container">
                 <h1>Register</h1>
                 <form method="POST" action="{{ route('register') }}">
                     @csrf
                     <div>
                         <label for="name">Name</label>
                         <input type="text" id="name" name="name" value="{{ old('name') }}" required>
                         @error('name') <span>{{ $message }}</span> @enderror
                     </div>
                     <div>
                         <label for="email">Email</label>
                         <input type="email" id="email" name="email" value="{{ old('email') }}" required>
                         @error('email') <span>{{ $message }}</span> @enderror
                     </div>
                     <div>
                         <label for="password">Password</label>
                         <input type="password" id="password" name="password" required>
                         @error('password') <span>{{ $message }}</span> @enderror
                     </div>
                     <div>
                         <label for="password_confirmation">Confirm Password</label>
                         <input type="password" id="password_confirmation" name="password_confirmation" required>
                     </div>
                     <button type="submit">Register</button>
                 </form>
                 <p>Already have an account? <a href="{{ route('login') }}">Login</a></p>
             </div>
         </body>
         </html>`
          },
          {
            "bootstrap_title_heading": "Create Dashboard Page",
            "bootstrap_title_desc": `Create a dashboard view at resources/views/dashboard.blade.php:
      
         <!DOCTYPE html>
         <html>
         <head>
             <title>Dashboard</title>
             <link rel="stylesheet" href="{{ asset('css/app.css') }}">
         </head>
         <body>
             <div class="container">
                 <h1>Welcome, {{ Auth::user()->name }}</h1>
                 <p>This is your dashboard where you can manage your account and view your orders.</p>
                 <a href="/cart">View Cart</a>
                 <a href="/logout" 
                    onclick="event.preventDefault(); document.getElementById('logout-form').submit();">
                    Logout
                 </a>
                 <form id="logout-form" action="{{ route('logout') }}" method="POST" style="display: none;">
                     @csrf
                 </form>
             </div>
         </body>
         </html>`
          },
          {
            "bootstrap_title_heading": "Update Routes for Pages",
            "bootstrap_title_desc": `Update routes in routes/web.php:
      
         // Public routes
         Route::get('/', function () {
             return view('welcome');
         });
      
         // Auth routes
         Route::middleware(['auth'])->group(function () {
             Route::get('/dashboard', function () {
                 return view('dashboard');
             })->name('dashboard');
      
             Route::get('/cart', [ShoppingCartController::class, 'index']);
         });
      
         // Logout route
         Route::post('/logout', [App\Http\Controllers\Auth\AuthenticatedSessionController::class, 'destroy'])->name('logout');
      `
          }
        ],
        "coursename": "E-Commerce Auth Pages (day-11)"
      }
            
      
    


]
export default LaravelJson