import "./Footer.scss";
import { FaLocationArrow,FaMobileAlt,FaEnvelope, FaLink } from "react-icons/fa";
import Payment from "../../assets/payments.png"
import {AiOutlineWhatsApp} from "react-icons/ai"
import {ImLocation} from "react-icons/im"
import { useContext} from "react";
import MyContext from "../../Uttis/MyContext";







const Footer = () => {
    

const {navigate,location} = useContext(MyContext)
  
   
    
 
  
if (location.pathname.match(/^\/(html|learning|css|certi-deetails|student-registration|css-advance|javascript|project|reactjs|react-native|vxeditor|express-js|excel|cyber-security|php)\//) || location.pathname === '/vxeditor')  {
    return null;
  }
 

    return <footer className="footer">
    <div className="footer-content">
      


        <div className="col">
        <div className="tmitless">About</div>
       
        <div className="c-item">
        <FaLink/>
        <div className="text" style={{cursor:"default"}}> "V-Ex Tech Solution is a software development company that provides IT training and internship programs."</div>
    </div>
        <div className="c-item">
            <FaLocationArrow/>
            <div className="text" style={{cursor:"default"}}>301,Dhun Complex
             Nizampura,Opp Pavan Park Society,Nava yard Road,Above Riya Bridal vadodara-390002</div>
        </div>

        <div className="c-item">
            <FaMobileAlt/>
            <div className="text" style={{cursor:"default"}}>+91 9664768292</div>
        </div>
       

        <div className="c-item">
            <FaEnvelope/>
            <div className="text" style={{cursor:"default"}}>hrteam@v-extechsolution.in</div>
        </div>

        <div className="c-item">
            <AiOutlineWhatsApp/>
            <div className="text" style={{cursor:"default"}}>9664768292</div>
        </div>

      <div className="c-item">
            <ImLocation/>
            <div className="text" style={{cursor:"default"}}><a href="https://g.co/kgs/fAm1bj" target="_blank">Google Map Location</a></div>
        </div>
       
        </div>

       <div className="col">
        <div className="tmitless">V-Ex World</div>
        <span className="text" onClick={() => window.open('https://v-extechsolution.in/')}>V Ex Tech Solution</span>
        <span className="text" onClick={() => window.open('https://components.v-extechsolution.in/')}>Open Souce Ui</span>
        <span className="text" onClick={() => window.open('https://vhx.v-extechsolution.in/')}>VHX View</span>
        <span className="text" onClick={() =>window.open('https://veerconsultancyglobal.com/')}>Veer Consultancy</span>
       <span className="text" onClick={() =>window.open('https://vemox.v-extechsolution.in/')}>Vemox</span>
       
   

       
        
        </div>

      

        <div className="col">
        <div className="tmitless">Services</div>
        
        <span className="text">Data Analytics</span>
        <span className="text">Mobile App Development</span>
        <span className="text">Web Development</span>
        <span className="text">E-Commerce Developement</span>
        <span className="text">Web Design</span>
        <span className="text">Admin Panel</span>
       
        
        </div>
      

        <div className="col">
        <div className="tmitless">Quick Links</div>
        <span className="text" onClick={() => navigate('/tools')}>Tools</span>
        
        <span className="text" onClick={() => navigate('/vxeditor')}>Code Editor</span>
        <span className="text" onClick={() =>navigate('/certi-details')}>Verify Certificate</span>
        <span className="text" onClick={() =>navigate('/student-registration')}>Student Registration</span>
        <span className="text" onClick={() =>navigate('/internship-report')}>Internship-report</span>
       <span className="text" onClick={() =>window.open('https://components.v-extechsolution.in/','_blank')}>Components</span>
        <span className="text" onClick={() =>navigate('/terms-condition')}>Terms And Condition</span>
   

       
        
        </div>
        
    </div>

    <div className="bottom-bar">
        <div className="bottom-bar-content">
            <div className="text">
                &#169; Copyrights Reserved - V-Ex Tech 2023 .Software Solution.
            </div>
            <img src={Payment}/>
        </div>
    </div>


    </footer>
  
   
}

export default Footer;