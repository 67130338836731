const PythonJson = [
{
"id": 1,
"html_title": "intro",
"bootstrap_head_desc": [
{
"bootstrap_title_heading": "Introduction",
"bootstrap_title_desc": `Python is a popular programming language known for its simplicity and versatility.
It is widely used for web development, data analysis, artificial intelligence, scientific computing, and more.
Python’s syntax is easy to learn, which makes it a great choice for beginners and experts alike.`
},
{
"bootstrap_title_heading": "Usage",
"bootstrap_title_desc": `-- Python is used for web development, with frameworks such as Django and Flask.

-- It is also used for data analysis and machine learning with libraries like Pandas and TensorFlow.

-- Python can be used for scripting, automation, and even game development.`,
"copy": true
},
{
"bootstrap_title_heading": "Installation",
"bootstrap_title_desc": `To install Python, you can download the latest version from the official Python website (python.org). Python comes pre-installed on most Linux and macOS systems. For Windows, an installer is available on the website.

Holding Keywords:
1) pip -- for installing packages
2) virtualenv -- for creating isolated environments
3) import -- for importing libraries`,
"copy": true
}
],
"coursename": "Python Intro (day-1)"
},

{
"id": 2,
"html_title": "Python Basics",
"bootstrap_head_desc": [
{
"bootstrap_title_heading": "Syntax and Comments",
"bootstrap_title_desc": `Python syntax is clean and easy to read.
Comments in Python are marked by a # symbol.
Example:
# This is a comment
print("Hello, World!")`
},
{
"bootstrap_title_heading": "Variables",
"bootstrap_title_desc": `Variables in Python are created when you assign a value to them.
No declaration is required.
Example:
x = 5
y = "Hello, World!"`
},
{
"bootstrap_title_heading": "Data Types",
"bootstrap_title_desc": `Python supports various data types including int, float, str, list, and dict.
Example:
x = 5 # int
y = "Hello" # str
z = 3.14# float`
}
],
"coursename": "Python Basics (day-2)"
},

{
"id": 3,
"html_title": "Control Flow",
"bootstrap_head_desc": [
{
"bootstrap_title_heading": "If Statements",
"bootstrap_title_desc": "Python uses 'if', 'elif', and 'else' statements for decision making.\nExample:\nx = 10\nif x > 5:\nprint('x is greater than 5')\nelif x == 5:\nprint('x is 5')\nelse:\nprint('x is less than 5')"
},
{
"bootstrap_title_heading": "For Loops",
"bootstrap_title_desc": "A 'for' loop is used to iterate over a sequence (like a list, tuple, or string).\nExample:\nfruits = ['apple', 'banana', 'cherry']\nfor fruit in fruits:\nprint(fruit)"
},
{
"bootstrap_title_heading": "While Loops",
"bootstrap_title_desc": "A 'while' loop runs as long as a condition is 'True'.\nExample:\ni = 1\nwhile i < 6:\nprint(i)\ni += 1"
}
],
"coursename": "Control Flow (day-3)"
},


{
"id": 4,
"html_title": "Functions and Modules",
"bootstrap_head_desc": [
{
"bootstrap_title_heading": "Functions",
"bootstrap_title_desc": `Functions in Python are defined using the 'def' keyword.
Example:
def greet(name):
return 'Hello, ' + name

message = greet('Alice')
print(message)`
},
{
"bootstrap_title_heading": "Built-in Functions",
"bootstrap_title_desc": `Python has many built-in functions like 'len()', 'max()', and 'min()'.
Example:
numbers = [1, 2, 3, 4, 5]
length = len(numbers)
print(length)`
},
{
"bootstrap_title_heading": "Modules",
"bootstrap_title_desc": `Modules are Python files that contain functions and variables
you can include in your projects.
Example:
import math
result = math.sqrt(16)
print(result)`
}
],
"coursename": "Func & Modules (day-4)"
},

{
    "id": 5,
    "html_title": "Lists, Tuples, and Dictionaries",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Lists",
        "bootstrap_title_desc": `Lists are mutable sequences, typically used to store collections of homogeneous items.
  Example:
  fruits = ['apple', 'banana', 'cherry']
  fruits.append('orange')
  print(fruits)  # Output: ['apple', 'banana', 'cherry', 'orange']`
      },
      {
        "bootstrap_title_heading": "Tuples",
        "bootstrap_title_desc": `Tuples are immutable sequences, typically used to store collections of heterogeneous data.
  Example:
  coordinates = (10, 20)
  print(coordinates)  # Output: (10, 20)`
      },
      {
        "bootstrap_title_heading": "Dictionaries",
        "bootstrap_title_desc": `Dictionaries are used to store key-value pairs.
  Example:
  student = {'name': 'John', 'age': 25, 'courses': ['Math', 'CompSci']}
  print(student['name'])  # Output: John
  print(student['courses'])  # Output: ['Math', 'CompSci']`
      }
    ],
    "coursename": "Lists, T,D (day-5)"
  },

  {
    "id": 7,
    "html_title": "File Handling",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Opening and Reading Files",
        "bootstrap_title_desc": `In Python, you can open files using the built-in 'open()' function. The 'open()' function returns a file object, which provides methods and attributes to interact with the file. To read the contents of a file, you can use methods like 'read()', 'readline()', or 'readlines()'. The 'read()' method reads the entire file into a single string.
  
  Example:
  # Open a file in read mode
  file = open('example.txt', 'r')
  
  # Read the entire file content
  content = file.read()
  
  print(content)
  
  # Don't forget to close the file
  file.close()
  
  In this example, 'example.txt' is opened in read mode ('r'). The 'read()' method is used to get the entire content of the file, which is then printed. After reading, the file is closed using the 'close()' method to free up system resources.`
      },
      {
        "bootstrap_title_heading": "Writing to Files",
        "bootstrap_title_desc": `You can write to a file by opening it in write mode ('w'). If the file does not exist, it will be created. If it does exist, its content will be truncated. You can use the 'write()' method to add content to the file.
  
  Example:
  # Open a file in write mode
  file = open('example.txt', 'w')
  
  # Write content to the file
  file.write('Hello, World!\nThis is a new line.')
  
  # Close the file
  file.close()
  
  In this example, 'example.txt' is opened in write mode ('w'). The 'write()' method adds text to the file. If the file already contains data, it will be overwritten. After writing, the file is closed.`
      },
      {
        "bootstrap_title_heading": "Appending to Files",
        "bootstrap_title_desc": `To add content to the end of an existing file without truncating it, you can open the file in append mode ('a'). This mode allows you to add new content at the end of the file.
  
  Example:
  # Open a file in append mode
  file = open('example.txt', 'a')
  
  # Append content to the file
  file.write('\nThis line is appended.')
  
  # Close the file
  file.close()
  
  In this example, 'example.txt' is opened in append mode ('a'). The 'write()' method adds new content to the end of the file, preserving the existing data. After appending, the file is closed.`
      }
    ],
    "coursename": "File Handling (day-7)"
  }
,


{
    "id": 8,
    "html_title": "Exception Handling",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Try and Except",
        "bootstrap_title_desc": `Exception handling in Python is done using the 'try' and 'except' blocks. You use the 'try' block to write code that may potentially cause an exception, and the 'except' block to handle the exception if it occurs.
  
  Example:
  try:
      result = 10 / 0
  except ZeroDivisionError:
      print("You can't divide by zero!")
  
  In this example, the code inside the 'try' block attempts to divide a number by zero, which raises a 'ZeroDivisionError'. The 'except' block catches this specific exception and prints a user-friendly message.`
      },
      {
        "bootstrap_title_heading": "Finally Block",
        "bootstrap_title_desc": `The 'finally' block, if present, will be executed no matter whether an exception was raised or not. It is generally used for cleanup actions, such as closing files or releasing resources.
  
  Example:
  try:
      file = open('example.txt', 'r')
      content = file.read()
  finally:
      file.close()
  
  In this example, the 'finally' block ensures that the file is closed regardless of whether an exception occurred during the file operations.`
      },
      {
        "bootstrap_title_heading": "Raise Statement",
        "bootstrap_title_desc": `You can use the 'raise' statement to trigger an exception manually. This is useful for custom error handling or for situations where you need to enforce certain conditions in your code.
  
  Example:
  def divide(x, y):
      if y == 0:
          raise ValueError("The denominator cannot be zero.")
      return x / y
  
  try:
      result = divide(10, 0)
  except ValueError as e:
      print(e)
  
  In this example, the 'raise' statement manually triggers a 'ValueError' if the denominator is zero. The 'try' block attempts to perform the division, and the 'except' block catches and prints the custom error message.`
      }
    ],
    "coursename": "Exception Handling (day-8)"
  },



  {
    "id": 9,
    "html_title": "Object-Oriented Programming (OOP)",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Classes and Objects",
        "bootstrap_title_desc": `In Python, a class is a blueprint for creating objects. An object is an instance of a class. Classes encapsulate data for the object and methods to manipulate that data.
  
  Example:
  class Person:
      def __init__(self, name, age):
          self.name = name
          self.age = age
  
      def greet(self):
          return f"Hello, my name is {self.name} and I am {self.age} years old."
  
  # Create an object of the class
  person = Person('Alice', 30)
  print(person.greet())
  
  In this example, the 'Person' class has an initializer method '__init__()' to set the initial state of the object and a method 'greet()' to return a greeting message. An instance of the class is created with the name 'Alice' and age '30', and the 'greet()' method is called to print a message.`
      },
      {
        "bootstrap_title_heading": "Inheritance",
        "bootstrap_title_desc": `Inheritance allows a class to inherit attributes and methods from another class. The class inheriting is called the subclass, and the class being inherited from is called the superclass.
  
  Example:
  class Animal:
      def __init__(self, name):
          self.name = name
  
      def speak(self):
          return "Animal sound"
  
  class Dog(Animal):
      def speak(self):
          return "Woof!"
  
  # Create an object of the subclass
  dog = Dog('Buddy')
  print(dog.speak())
  
  In this example, the 'Dog' class inherits from the 'Animal' class. It overrides the 'speak()' method to provide a specific implementation. When the 'speak()' method is called on a 'Dog' object, it returns "Woof!" instead of the generic "Animal sound".`
      },
      {
        "bootstrap_title_heading": "Methods",
        "bootstrap_title_desc": `Methods are functions defined within a class that operate on instances of that class. They can access and modify the object’s attributes.
  
  Example:
  class Car:
      def __init__(self, make, model):
          self.make = make
          self.model = model
  
      def display_info(self):
          return f"{self.make} {self.model}"
  
  # Create an object of the class
  car = Car('Toyota', 'Corolla')
  print(car.display_info())
  
  In this example, the 'Car' class has a method 'display_info()' that returns a string containing the car's make and model. An instance of the 'Car' class is created, and the 'display_info()' method is used to print the car's information.`
      }
    ],
    "coursename": "OOP (day-9)"
  },


  {
    "id": 13,
    "html_title": "Regular Expressions",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Introduction to Regular Expressions",
        "bootstrap_title_desc": `Regular expressions (regex) are used for searching and manipulating strings based on patterns. The 're' module in Python provides support for regex operations.
  
  Example:
  import re
  
  pattern = r'\d+'  # Pattern to match one or more digits
  text = 'There are 24 apples and 12 oranges.'
  
  matches = re.findall(pattern, text)
  print(matches)  # Outputs: ['24', '12']
  
  In this example, the 'findall()' function finds all occurrences of the pattern (one or more digits) in the given text.`
      },
      {
        "bootstrap_title_heading": "Common re Module Functions",
        "bootstrap_title_desc": `The 're' module provides several functions for working with regular expressions:
  - 're.match()': Checks for a match only at the beginning of the string.
  - 're.search()': Searches for the first occurrence of the pattern anywhere in the string.
  - 're.findall()': Finds all occurrences of the pattern in the string.
  - 're.sub()': Replaces occurrences of the pattern with a specified string.
  
  Example:
  import re
  
  text = 'The email address is test@example.com.'
  pattern = r'\S+@\S+'
  
  match = re.search(pattern, text)
  if match:
      print(match.group())  # Outputs: test@example.com
  
  In this example, the 'search()' function finds the first occurrence of an email address pattern in the text, and 'group()' retrieves the matched string.`
      },
      {
        "bootstrap_title_heading": "Using Regular Expressions for Validation",
        "bootstrap_title_desc": `Regular expressions can be used to validate input formats, such as email addresses or phone numbers.
  
  Example:
  import re
  
  def validate_email(email):
      pattern = r'^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$'
      if re.match(pattern, email):
          return True
      return False
  
  email = 'user@example.com'
  print(validate_email(email))  # Outputs: True
  
  In this example, the 'validate_email()' function uses a regex pattern to check if the provided email address is in a valid format.`
      }
    ],
    "coursename": "Regular Expressions (day-10)"
  },

  {
    "id": 14,
    "html_title": "Working with JSON",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Introduction to JSON",
        "bootstrap_title_desc": `JSON (JavaScript Object Notation) is a lightweight data-interchange format that's easy for humans to read and write and easy for machines to parse and generate. Python provides the 'json' module to work with JSON data.
  
  Example:
  import json
  
  data = {
      "name": "John",
      "age": 30,
      "city": "New York"
  }
  
  json_string = json.dumps(data)
  print(json_string)  # Outputs: {"name": "John", "age": 30, "city": "New York"}
  
  In this example, the 'dumps()' function converts a Python dictionary into a JSON string.`
      },
      {
        "bootstrap_title_heading": "Reading JSON Data",
        "bootstrap_title_desc": `To read JSON data from a file or a JSON string, you use the 'json.loads()' function to parse the JSON string or 'json.load()' to read from a file.
  
  Example:
  import json
  
  json_string = '{"name": "Alice", "age": 25, "city": "London"}'
  data = json.loads(json_string)
  print(data)  # Outputs: {'name': 'Alice', 'age': 25, 'city': 'London'}
  
  In this example, the 'loads()' function parses a JSON string and converts it into a Python dictionary.`
      },
      {
        "bootstrap_title_heading": "Writing JSON Data",
        "bootstrap_title_desc": `You can write JSON data to a file using the 'json.dump()' function. This function writes a Python dictionary to a file in JSON format.
  
  Example:
  import json
  
  data = {
      "name": "Bob",
      "age": 40,
      "city": "Paris"
  }
  
  with open('data.json', 'w') as file:
      json.dump(data, file)
  
  In this example, the 'dump()' function writes the Python dictionary to a file named 'data.json'.`
      },
      {
        "bootstrap_title_heading": "Handling JSON Files",
        "bootstrap_title_desc": `You can combine reading and writing JSON files to manage data more effectively.
  
  Example:
  import json
  
  # Writing JSON data to a file
  data = {"name": "Charlie", "age": 35, "city": "Berlin"}
  with open('data.json', 'w') as file:
      json.dump(data, file)
  
  # Reading JSON data from a file
  with open('data.json', 'r') as file:
      loaded_data = json.load(file)
      print(loaded_data)  # Outputs: {'name': 'Charlie', 'age': 35, 'city': 'Berlin'}
  
  In this example, the JSON data is first written to 'data.json' and then read back from the file.`
      }
    ],
    "coursename": "Working with JSON (day-11)"
  },

  {
    "id": 15,
    "html_title": "Working with SQLite",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Introduction to SQLite",
        "bootstrap_title_desc": `SQLite is a lightweight, disk-based database that doesn’t require a separate server process. Python's 'sqlite3' module provides an interface for interacting with SQLite databases.
  
  Example:
  import sqlite3
  
  # Connect to SQLite database (or create it if it doesn't exist)
  conn = sqlite3.connect('example.db')
  
  # Create a cursor object
  cursor = conn.cursor()
  
  # Create a table
  cursor.execute('''CREATE TABLE IF NOT EXISTS users (id INTEGER PRIMARY KEY, name TEXT, age INTEGER)''')
  
  # Commit changes and close the connection
  conn.commit()
  conn.close()
  
  In this example, the 'connect()' function establishes a connection to the database, and 'cursor()' creates a cursor object to execute SQL commands. The table is created if it doesn't already exist.`
      },
      {
        "bootstrap_title_heading": "Inserting Data",
        "bootstrap_title_desc": `You can insert data into the SQLite database using the 'INSERT INTO' SQL statement.
  
  Example:
  import sqlite3
  
  conn = sqlite3.connect('example.db')
  cursor = conn.cursor()
  
  # Insert data into the table
  cursor.execute("INSERT INTO users (name, age) VALUES ('Alice', 30)")
  cursor.execute("INSERT INTO users (name, age) VALUES ('Bob', 25)")
  
  conn.commit()
  conn.close()
  
  In this example, the 'execute()' function is used to insert new records into the 'users' table.`
      },
      {
        "bootstrap_title_heading": "Querying Data",
        "bootstrap_title_desc": `To retrieve data from the database, use the 'SELECT' SQL statement.
  
  Example:
  import sqlite3
  
  conn = sqlite3.connect('example.db')
  cursor = conn.cursor()
  
  # Query data from the table
  cursor.execute("SELECT * FROM users")
  rows = cursor.fetchall()
  
  for row in rows:
      print(row)
  
  conn.close()
  
  In this example, 'fetchall()' retrieves all rows from the query result, and the results are printed to the console.`
      },
      {
        "bootstrap_title_heading": "Updating and Deleting Data",
        "bootstrap_title_desc": `You can update and delete records using the 'UPDATE' and 'DELETE' SQL statements.
  
  Example:
  import sqlite3
  
  conn = sqlite3.connect('example.db')
  cursor = conn.cursor()
  
  # Update data in the table
  cursor.execute("UPDATE users SET age = 31 WHERE name = 'Alice'")
  
  # Delete data from the table
  cursor.execute("DELETE FROM users WHERE name = 'Bob'")
  
  conn.commit()
  conn.close()
  
  In this example, the 'UPDATE' statement modifies existing records, and the 'DELETE' statement removes records from the table.`
      }
    ],
    "coursename": "Working with SQLite (day-12)"
  },
  

  {
    "id": 15,
    "html_title": "simple-calculator-with-python",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Simple Calculator",
        "bootstrap_title_desc": `def add(x, y):
    return x + y

def subtract(x, y):
    return x - y

def multiply(x, y):
    return x * y

def divide(x, y):
    return x / y

print("Select operation:")
print("1. Add")
print("2. Subtract")
print("3. Multiply")
print("4. Divide")

choice = input("Enter choice (1/2/3/4): ")

num1 = float(input("Enter first number: "))
num2 = float(input("Enter second number: "))

if choice == '1':
    print(f"The result is: {add(num1, num2)}")
elif choice == '2':
    print(f"The result is: {subtract(num1, num2)}")
elif choice == '3':
    print(f"The result is: {multiply(num1, num2)}")
elif choice == '4':
    print(f"The result is: {divide(num1, num2)}")
else:
    print("Invalid input")
`
      },
    
    ],
    "coursename": "Project 1"
  },

  {
    "id": 15,
    "html_title": "To-Do List Application",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "To-Do List Application",
        "bootstrap_title_desc": `def view_tasks():
    with open("tasks.txt", "r") as file:
        tasks = file.readlines()
        for task in tasks:
            print(task.strip())

def add_task(task):
    with open("tasks.txt", "a") as file:
        file.write(task + "\n")

def delete_task(task_number):
    with open("tasks.txt", "r") as file:
        tasks = file.readlines()
    with open("tasks.txt", "w") as file:
        for i, task in enumerate(tasks):
            if i != task_number - 1:
                file.write(task)

print("1. View tasks")
print("2. Add task")
print("3. Delete task")

choice = input("Enter choice (1/2/3): ")

if choice == '1':
    view_tasks()
elif choice == '2':
    task = input("Enter task: ")
    add_task(task)
elif choice == '3':
    view_tasks()
    task_number = int(input("Enter task number to delete: "))
    delete_task(task_number)
else:
    print("Invalid choice")
`
      },
    
    ],
    "coursename": "Project 2"
  },


  {
    "id": 15,
    "html_title": "Number Guessing Game",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Number Guessing Game",
        "bootstrap_title_desc": `import random

def guess_number():
    number = random.randint(1, 100)
    attempts = 0
    while True:
        guess = int(input("Guess the number (1-100): "))
        attempts += 1
        if guess < number:
            print("Too low!")
        elif guess > number:
            print("Too high!")
        else:
            print(f"Congratulations! You guessed the number in {attempts} attempts.")
            break

guess_number()
`
      },
    
    ],
    "coursename": "Project 3"
  },


  {
    "id": 15,
    "html_title": "Weather App",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Weather App",
        "bootstrap_title_desc": `import requests

def get_weather(city):
    api_key = "your_api_key_here"  # Replace with your API key
    url = f"http://api.openweathermap.org/data/2.5/weather?q={city}&appid={api_key}&units=metric"
    response = requests.get(url)
    data = response.json()
    if data["cod"] == 200:
        main = data["main"]
        weather = data["weather"][0]["description"]
        print(f"Temperature: {main['temp']}°C")
        print(f"Weather: {weather}")
    else:
        print("City not found")

city = input("Enter city name: ")
get_weather(city)
`
      },
    
    ],
    "coursename": "Project 4"
  },

  {
    "id": 15,
    "html_title": "Simple Web Scraper",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Simple Web Scraper",
        "bootstrap_title_desc": `import requests
from bs4 import BeautifulSoup

def scrape_titles(url):
    response = requests.get(url)
    soup = BeautifulSoup(response.text, 'html.parser')
    titles = soup.find_all('h1')
    for title in titles:
        print(title.text)

url = input("Enter the URL to scrape: ")
scrape_titles(url)
`
      },
    
    ],
    "coursename": "Project 5"
  },


  {
    "id": 15,
    "html_title": "Personal Budget Tracker",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Personal Budget Tracker",
        "bootstrap_title_desc": `import json

def add_entry(entries):
    amount = float(input("Enter amount: "))
    description = input("Enter description: ")
    entry = {"amount": amount, "description": description}
    entries.append(entry)

def view_entries(entries):
    for entry in entries:
        print(f"{entry['description']}: '$'{entry['amount']}")

def main():
    try:
        with open("budget.json", "r") as file:
            entries = json.load(file)
    except FileNotFoundError:
        entries = []

    while True:
        print("1. Add entry")
        print("2. View entries")
        print("3. Exit")
        choice = input("Enter choice (1/2/3): ")
        if choice == '1':
            add_entry(entries)
        elif choice == '2':
            view_entries(entries)
        elif choice == '3':
            with open("budget.json", "w") as file:
                json.dump(entries, file)
            break
        else:
            print("Invalid choice")

main()
`
      },
    
    ],
    "coursename": "Project 6"
  },

  {
    "id": 15,
    "html_title": "Simple Chatbot",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Simple Chatbot",
        "bootstrap_title_desc": `def chatbot_response(user_input):
    responses = {
        "hello": "Hi there!",
        "how are you": "I'm good, thank you!",
        "bye": "Goodbye!"
    }
    return responses.get(user_input.lower(), "I don't understand that.")

while True:
    user_input = input("You: ")
    if user_input.lower() == "bye":
        print("Chatbot: Goodbye!")
        break
    print(f"Chatbot: {chatbot_response(user_input)}")
`
      },
    
    ],
    "coursename": "Project 7"
  },
  

  {
    "id": 15,
    "html_title": "Todo List Web App with Flask",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Todo List Web App with Flask",
        "bootstrap_title_desc": `from flask import Flask, render_template, request, redirect

app = Flask(__name__)
tasks = []

@app.route('/')
def index():
    return render_template('index.html', tasks=tasks)

@app.route('/add', methods=['POST'])
def add():
    task = request.form.get('task')
    if task:
        tasks.append(task)
    return redirect('/')

if __name__ == "__main__":
    app.run(debug=True)
`
      },

      {
        "bootstrap_title_heading": "index.html",
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>
  <h1>Todo List</h1>
  <ul>
    {% for task in tasks %}
    <li>{{ task }}</li>
    {% endfor %}
  </ul>
  <form method="post" action="/add">
    <input type="text" name="task" required>
    <input type="submit" value="Add Task">
  </form>
</body>
</html>
`
      },
    
    ],
    "coursename": "Project 8"
  },

  {
    "id": 15,
    "html_title": "Machine Learning Model",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Machine Learning Model",
        "bootstrap_title_desc": `from sklearn.datasets import load_iris
from sklearn.model_selection import train_test_split
from sklearn.ensemble import RandomForestClassifier
from sklearn.metrics import accuracy_score

# Load dataset
iris = load_iris()
X = iris.data
y = iris.target

# Split dataset
X_train, X_test, y_train, y_test = train_test_split(X, y, test_size=0.3, random_state=42)

# Train model
model = RandomForestClassifier()
model.fit(X_train, y_train)

# Make predictions
predictions = model.predict(X_test)

# Evaluate model
accuracy = accuracy_score(y_test, predictions)
print(f"Model Accuracy: {accuracy:.2f}")
`
      },

      {
        "bootstrap_title_heading": "index.html",
        "bootstrap_title_desc": `<!DOCTYPE html>
<html>
<body>
  <h1>Todo List</h1>
  <ul>
    {% for task in tasks %}
    <li>{{ task }}</li>
    {% endfor %}
  </ul>
  <form method="post" action="/add">
    <input type="text" name="task" required>
    <input type="submit" value="Add Task">
  </form>
</body>
</html>
`
      },
    
    ],
    "coursename": "Project 9"
  },


  {
    "id": 16,
    "html_title": "create api",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "install flask",
        "bootstrap_title_desc": `pip install flask
`
      },


      {
        "bootstrap_title_heading": "app.py",
        "bootstrap_title_desc": `from flask import Flask, jsonify, render_template

app = Flask(__name__)

# Sample data
data = [
    {"id": 1, "name": "Alice", "role": "Developer"},
    {"id": 2, "name": "Bob", "role": "Designer"},
    {"id": 3, "name": "Charlie", "role": "Product Manager"}
]

# API route to return data as JSON
@app.route('/api/data', methods=['GET'])
def get_data():
    return jsonify(data)

# Route to render HTML page
@app.route('/')
def home():
    return render_template('index.html', data=data)

if __name__ == '__main__':
    app.run(debug=True)
`
      },

      {
        "bootstrap_title_heading": "index.html",
        "bootstrap_title_desc": `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Data Display</title>
</head>
<body>
    <h1>Data from API</h1>
    <table border="1">
        <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Role</th>
        </tr>
        {% for item in data %}
        <tr>
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.role }}</td>
        </tr>
        {% endfor %}
    </table>
</body>
</html>
`
      },

      {
        "bootstrap_title_heading": "run",
        "bootstrap_title_desc": `python app.py`
      },

   
    
    ],
    "coursename": "Project Api"
  },


  {
    "id": 16,
    "html_title": "sales-data-analysis",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Project Overview",
        "bootstrap_title_desc": `Loading Sales Data: Analyzing basic sales performance.

Performing Exploratory Data Analysis (EDA): Understanding patterns in the data.

Creating Visualizations: Using graphs to summarize key insights.`
      },

      {
        "bootstrap_title_heading": "Sales Data Analysis(take random csv file)",
        "bootstrap_title_desc": `import pandas as pd
import matplotlib.pyplot as plt
import seaborn as sns

df = pd.read_csv('sales_data.csv')

print("Dataset Overview:")
print(df.head())
print("\nSummary Statistics:")
print(df.describe())
print("\nColumn Information:")
print(df.info())

print("\nMissing Values:")
print(df.isnull().sum())

df = df.dropna()

sales_by_product = df.groupby('Product')['Sales'].sum()
print("\nTotal Sales by Product:")
print(sales_by_product)

sales_by_region = df.groupby('Region')['Sales'].sum()
print("\nTotal Sales by Region:")
print(sales_by_region)

plt.figure(figsize=(8, 5))
sales_by_product.plot(kind='bar', color='skyblue')
plt.title('Total Sales by Product')
plt.ylabel('Sales')
plt.xlabel('Product')
plt.xticks(rotation=45)
plt.show()

plt.figure(figsize=(8, 5))
sales_by_region.plot(kind='pie', autopct='%1.1f%%', startangle=90, colors=sns.color_palette('pastel'))
plt.title('Sales Distribution by Region')
plt.ylabel('')
plt.show()

if 'Date' in df.columns:
    df['Date'] = pd.to_datetime(df['Date'])
    monthly_sales = df.groupby(df['Date'].dt.to_period('M'))['Sales'].sum()

    plt.figure(figsize=(10, 6))
    monthly_sales.plot(marker='o', color='green')
    plt.title('Monthly Sales Trend')
    plt.ylabel('Sales')
    plt.xlabel('Month')
    plt.grid()
    plt.show()

print("\nKey Insights:")
print("1. Best-selling product:", sales_by_product.idxmax())
print("2. Region with highest sales:", sales_by_region.idxmax())
`
      },


    

   
    
    ],
    "coursename": "Sales Data Analysis"
  },

  {
    "id": 16,
    "html_title": "analysis-project",
    "bootstrap_head_desc": [
      {
        "bootstrap_title_heading": "Project Overview",
        "bootstrap_title_desc": `Build a dashboard to analyze and visualize sales trends, customer
demographics, and product performance. Use Python and libraries like Pandas, Matplotlib, Seaborn, and optionally
Streamlit or Plotly for interactive visualizations.`
      },

      {
        "bootstrap_title_heading": "Week 1: Data Understanding and Preparation",
        "bootstrap_title_desc": `Day 1-2: Learn the basics of Pandas, Matplotlib, and Seaborn.

Practice loading, exploring, and cleaning small datasets.
Resources: Pandas and Seaborn documentation.


Day 3-4: Collect or create your dataset.

If using real data, find datasets on platforms like Kaggle (e.g., sales data).
Ensure it has columns like Date, Region, Product, Customer_Age, Sales, etc.


Day 5-7: Clean and preprocess the data.

Handle missing values.
Convert columns to appropriate data types.
Create additional columns like Month, Year, or Profit (if relevant).`
      },

      {
        "bootstrap_title_heading": "Week 2: Exploratory Data Analysis (EDA)",
        "bootstrap_title_desc": `Day 8-10: Perform EDA on the dataset.

Analyze trends in sales by product, region, and customer demographics.
Use group-by and aggregation functions.
Explore seasonal patterns (e.g., monthly trends).

Day 11-12: Visualize key insights.

Use bar charts, line plots, pie charts, and heatmaps.
Focus on sales trends, best-selling products, and regional performance.

Day 13-14: Summarize findings in a structured format.

Identify strengths, weaknesses, and opportunities in sales data.
Note anomalies or surprising trends.`
      },

      {
        "bootstrap_title_heading": "Week 3: Advanced Visualization and Reporting",
        "bootstrap_title_desc": `Day 15-17: Build a dashboard prototype.

Use Matplotlib and Seaborn for static plots.
Optionally use Plotly or Streamlit for interactive dashboards.

Day 18-20: Add key performance indicators (KPIs).

Examples: Total Sales, Average Sales, Best Region, Customer Retention Rate.

Day 21: Test your dashboard.

Validate data accuracy.
Gather feedback from peers or mentors.`
      },

      {
        "bootstrap_title_heading": "Week 4: Documentation and Presentation",
        "bootstrap_title_desc": `Day 22-24: Document the project.

Write a project report covering objectives, methodology, and insights.
Include visualizations and a discussion of findings.

Day 25-26: Polish the dashboard.

Make improvements based on feedback.
Add interactivity or refine visual appeal.

Day 27-28: Prepare a presentation.

Create slides summarizing your project.
Include key insights, methodologies, and your dashboard.


Day 29-30: Present your project.

Simulate presenting to an internship team.
Practice answering questions about your approach and findings.`
      },


      

   


    

   
    
    ],
    "coursename": "Analysis project"
  },
  
  
  
  
  
  
  
  



];

export default PythonJson;
