import React from 'react'
import './WebBatch.scss'
import Banner from './Banner/Banner'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import Data from './Json'
import Info from './Info/Info'

const WebBatch = () => {

  const {batch} =useParams()


  return (
    <div className='main-web'>

    <Helmet>
    <title>{batch} in vadodara</title>
            <meta name="title" content={`${batch} In Vadodara for IT Students`}/>
            <meta name="description" content={`V-Ex Tech Provides Best ${batch} in Vadodara, The ${batch} covers modules in variation of langauges... .`} />
            </Helmet>
        {

          Data.filter(a=>a.param===batch || a.cparam===batch)
          .map((item)=>{

          return(
<>
         <Banner data={batch.includes('internship')?item.batch:item.cbatch}/>

         <Info outer={item} batch={batch}/>

         </>
          )
          })
        }
   
    
   
    </div>
  )
}

export default WebBatch