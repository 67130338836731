



const Data = [

  

    {
        "id": 1,
        "batch": "Frontend Development Internship",
        "param": "frontend-development-internship",
        "cbatch": "Frontend Development Training course ",
        "cparam": "frontend-development-training",
        "duration":'3-4 Months',
        'fees':'20 Thousand',
        "container": [
          {
            "id": 1,
            "name": "Frontend Basics",
            "topics": [
              { "id": 1, "list": "What is Web?" },
              { "id": 2, "list": "HTML Basics" },
              { "id": 3, "list": "Table & Forms" },
              { "id": 4, "list": "Lists & Divs" },
              { "id": 5, "list": "CSS Introduction" },
              { "id": 6, "list": "Selector Types" },
              { "id": 7, "list": "Box Model" },
              { "id": 8, "list": "Transform" },
              { "id": 9, "list": "Animation" },
              { "id": 10, "list": "Flexbox & Grid" },
              { "id": 11, "list": "Media Queries" },
              { "id": 12, "list": "Bootstrap Basics" },
              { "id": 13, "list": "JavaScript Essentials" },
              { "id": 14, "list": "String Methods" },
              { "id": 15, "list": "Functions & Scope" },
              { "id": 16, "list": "Arrays & Events" },
              { "id": 17, "list": "DOM Manipulation" },
              { "id": 18, "list": "Objects & Classes" },
              { "id": 19, "list": "Callbacks" },
              { "id": 20, "list": "Bootstrap Components" },
              { "id": 21, "list": "Control Flow (If/Else)" },
              { "id": 22, "list": "Loops (For/While)" },
              { "id": 23, "list": "Regex Basics" },
              { "id": 24, "list": "Arrow Functions" },
              { "id": 25, "list": "Form Validation" },
              { "id": 26, "list": "Modules & Imports" }
            ]
          },
          {
            "id": 2,
            "name": "React JS",
            "topics": [
              { "id": 1, "list": "API Calls with Axios" },
              { "id": 2, "list": "React Hooks Overview" },
              { "id": 3, "list": "React Router Basics" },
              { "id": 4, "list": "useCallback Hook" },
              { "id": 5, "list": "Dynamic Routing" },
              { "id": 6, "list": "NPM Basics" },
              { "id": 7, "list": "Form Validation in React" },
              { "id": 8, "list": "Dependent Dropdowns" }
            ]
          },
        
          {
            "id": 3,
            "name": "Intern Projects",
            "topics": [
              { "id": 1, "list": "Building a Personal Portfolio" },
              { "id": 2, "list": "Responsive Web Design" },
              { "id": 3, "list": "Interactive Web Elements" },
              { "id": 4, "list": "E-commerce Website" }
            ]
          },
          {
            "id": 4,
            "name": "Internship Tools & Deployment",
            "topics": [
              { "id": 1, "list": "Git & Version Control" },
              { "id": 2, "list": "Using Terminal Commands" },
              { "id": 3, "list": "Code Reviews & Best Practices" },
              { "id": 3, "list": "Live Hosting Overview" }
            ]
          },
        
        
        ]
      },
      

      {
        "id": 2,
        "batch": "Backend Development Internship",
        "param": "backend-development-internship",
        "cbatch": "backend Development Training course ",
        "cparam": "backend-development-training",
        "duration":'3-4 Months',
        'fees':'15 Thousand',
        "container": [
          {
            "id": 1,
            "name": "Backend Basics",
            "topics": [
              { "id": 1, "list": "What is Backend?" },
              { "id": 2, "list": "Express.js Basics" },
              { "id": 3, "list": "Understanding HTTP & REST APIs" },
        { "id": 4, "list": "Node.js Basics" },
        { "id": 5, "list": "NPM & Package Management" },
        { "id": 6, "list": "Environment Variables" },
        { "id": 7, "list": "Express.js Essentials" },
        { "id": 8, "list": "Middleware in Express" },
        { "id": 9, "list": "Routing and Controllers" }
            ]
          },
          {
            "id": 2,
            "name": "Advanced Backend Concepts",
            "topics": [
                { "id": 1, "list": "Authentication & Authorization" },
                { "id": 2, "list": "User Sessions and Cookies" },
                { "id": 3, "list": "JSON Web Tokens (JWT)" },
                { "id": 4, "list": "File Uploads and Management" },
                { "id": 5, "list": "Error Handling and Logging" },
                { "id": 6, "list": "Postman for API Testing" },
                { "id": 7, "list": "Role-Based Access Control" },
                { "id": 8, "list": "Data Encryption Techniques" }
                
            ]
          },
          {
            "id": 3,
            "name": "Database Integration",
            "topics": [
              { "id": 1, "list": "Introduction to MongoDB" },
              { "id": 2, "list": "MongoDB CRUD Operations" },
              { "id": 3, "list": "Mongoose Basics" },
              { "id": 4, "list": "Schema Design" },
              { "id": 5, "list": "Database Modeling and Design" },
              { "id": 6, "list": "Connecting Databases with Node.js" },
           
            ]
          },
        
          {
            "id": 4,
            "name": "Intern Projects & Deployment",
            "topics": [
              { "id": 1, "list": "Building a RESTful API" },
              { "id": 2, "list": "Blog Application with Authentication" },
              { "id": 3, "list": "E-commerce Backend (with Database)" },
              { "id": 4, "list": "Real-time Chat App Backend" },
              { "id": 5, "list": "Deploying to Cloud (Heroku, AWS, or DigitalOcean)" },
            ]
          }
        ]
      },

      {
        "id": 3,
        "batch": "Fullstack Development Internship",
        "param": "fullstack-development-internship",
        "cbatch": "Fullstack Development Training course ",
        "cparam": "fullstack-development-training",
        "duration":'5-6 Months',
        'fees':'35 Thousand',
        "container": [
          {
            "id": 1,
            "name": "Frontend Development",
            "topics": [
              { "id": 1, "list": "HTML & CSS Essentials" },
              { "id": 2, "list": "JavaScript Fundamentals" },
              { "id": 3, "list": "Responsive Design with Flexbox & Grid" },
              { "id": 4, "list": "CSS Frameworks (Bootstrap/Tailwind)" },
              { "id": 5, "list": "JavaScript ES6+ Features" },
              { "id": 6, "list": "DOM Manipulation and Events" },
              { "id": 7, "list": "React Basics" },
              { "id": 8, "list": "React Router & State Management" },
              { "id": 9, "list": "API Calls with Axios" }
            ]
          },
          {
            "id": 2,
            "name": "Backend Development",
            "topics": [
              { "id": 1, "list": "Node.js and Express Basics" },
              { "id": 2, "list": "RESTful API Design" },
              { "id": 3, "list": "Database Management (MongoDB)" },
              { "id": 4, "list": "Authentication & Authorization (JWT)" },
              { "id": 5, "list": "Data Validation & Middleware" },
              { "id": 6, "list": "File Handling and Uploads" },
              { "id": 7, "list": "Advanced Error Handling" }
            ]
          },
          {
            "id": 3,
            "name": "Fullstack Integration",
            "topics": [
              { "id": 1, "list": "Connecting Frontend and Backend" },
              { "id": 2, "list": "Handling Cross-Origin Requests (CORS)" },
              { "id": 3, "list": "Creating a Basic CRUD Application" },
              { "id": 4, "list": "State Management in Fullstack Applications" },
              { "id": 5, "list": "Session Management and Cookies" }
            ]
          },
          {
            "id": 4,
            "name": "Project Development",
            "topics": [
              { "id": 1, "list": "Portfolio Website with Contact Form" },
              { "id": 2, "list": "Todo App with User Authentication" },
              { "id": 3, "list": "E-commerce Application (Product Listing, Cart, Payment Integration)" },
              { "id": 4, "list": "Blog Platform with User Registration" }
            ]
          },
       
        ]
      },

      {
        "id": 4,
        "batch": "MERN Stack Development Internship",
        "param": "mern-stack-development-internship",
        "cbatch": "Mern Stack Development Training course ",
        "cparam": "mern-stack-development-training",
        "duration":'3-4 Months',
        'fees':'20 Thousand',
        "container": [
          {
            "id": 1,
            "name": "Frontend Development with React",
            "topics": [
              { "id": 1, "list": "React Basics (Components, JSX)" },
              { "id": 2, "list": "State and Props in React" },
              { "id": 3, "list": "React Router for Navigation" },
              { "id": 4, "list": "React Hooks (useState, useEffect)" },
              { "id": 5, "list": "Building Forms with Validation" },
              { "id": 6, "list": "Context API for State Management" },
              { "id": 7, "list": "API Integration using Axios" }
            ]
          },
          {
            "id": 2,
            "name": "Backend Development with Node.js and Express",
            "topics": [
              { "id": 1, "list": "Node.js Basics" },
              { "id": 2, "list": "Express.js Overview" },
              { "id": 3, "list": "RESTful API Design" },
              { "id": 4, "list": "Middleware and Routing in Express" },
              { "id": 5, "list": "Authentication with JWT" },
              { "id": 6, "list": "File Uploads & Handling" },
              { "id": 7, "list": "Environment Variables and Configuration" }
            ]
          },
          {
            "id": 3,
            "name": "Database Management with MongoDB",
            "topics": [
              { "id": 1, "list": "Introduction to MongoDB" },
              { "id": 2, "list": "CRUD Operations with Mongoose" },
              { "id": 3, "list": "Data Modeling and Schema Design" },
              { "id": 4, "list": "Database Relationships" },
              { "id": 5, "list": "Aggregation Framework Basics" },
              { "id": 6, "list": "Optimizing Queries for Performance" }
            ]
          },
       
          {
            "id": 4,
            "name": "Project Development & Deployment",
            "topics": [
              { "id": 1, "list": "User Authentication System" },
              { "id": 2, "list": "E-commerce Platform (Product Listing, Cart, Payment)" },
              { "id": 3, "list": "Social Media App (Posts, Likes, Comments)" },
              { "id": 4, "list": "Admin Dashboard with Analytics" },
              { "id": 5, "list": "Hosting on Cloud Providers (Heroku, AWS)" },
            ]
          },
       
        ]
      },

      {
        "id": 5,
        "batch": "UI/UX Design Internship",
        "param": "ui-ux-design-internship",
        "cbatch": "UI/Ux Training course ",
        "cparam": "ui-ux-training",
        "duration":'2-3 Months',
        'fees':'15 Thousand',
        "container": [
          {
            "id": 1,
            "name": "UI/UX Design Essentials",
            "topics": [
              { "id": 1, "list": "Introduction to UI & UX" },
              { "id": 2, "list": "Human-Centered Design" },
              { "id": 3, "list": "User Research & Personas" },
              { "id": 4, "list": "Information Architecture & User Flows" }
            ]
          },
          {
            "id": 2,
            "name": "Design Tools & Software",
            "topics": [
              { "id": 1, "list": "Getting Started with Figma" },
              { "id": 2, "list": "Adobe XD Basics" },
              { "id": 3, "list": "Creating Wireframes & Mockups" },
              { "id": 4, "list": "Prototype Interactions" }
            ]
          },
          {
            "id": 3,
            "name": "UI Design Principles",
            "topics": [
              { "id": 1, "list": "Color Theory & Application" },
              { "id": 2, "list": "Typography & Fonts in UI" },
              { "id": 3, "list": "Layout & Spacing" },
              { "id": 4, "list": "Building Accessible Designs" }
            ]
          },
          {
            "id": 4,
            "name": "Project & Portfolio Development",
            "topics": [
              { "id": 1, "list": "Responsive Website Design" },
              { "id": 2, "list": "Creating a Design System" },
              { "id": 3, "list": "Mobile App UI Project" },
              { "id": 4, "list": "Building a Portfolio" }
            ]
          }
        ]
      },

      {
        "id": 6,
        "batch": "Data Analysis Internship",
        "param": "data-analysis-internship",
        "cbatch": "Data Analysis Training course ",
        "cparam": "data-analyis-training",
        "duration":'3-4 Months',
        'fees':'20 Thousand',
        "container": [
          {
            "id": 1,
            "name": "Data Analysis Fundamentals",
            "topics": [
              { "id": 1, "list": "Introduction to Data Analysis" },
              { "id": 2, "list": "Data Types & Structures" },
              { "id": 3, "list": "Data Collection & Cleaning" },
              { "id": 4, "list": "Exploratory Data Analysis (EDA)" }
            ]
          },
          {
            "id": 2,
            "name": "Data Analysis Tools & Techniques",
            "topics": [
              { "id": 1, "list": "Excel for Data Analysis" },
              { "id": 2, "list": "Python for Data Analysis" },
              { "id": 3, "list": "Pandas & Numpy Basics" },
              { "id": 4, "list": "Data Visualization with Matplotlib & Seaborn" }
            ]
          },
          {
            "id": 3,
            "name": "Statistical Analysis",
            "topics": [
              { "id": 1, "list": "Descriptive Statistics" },
              { "id": 2, "list": "Probability & Distributions" },
              { "id": 3, "list": "Hypothesis Testing" },
              { "id": 4, "list": "Correlation & Regression Analysis" }
            ]
          },
          {
            "id": 4,
            "name": "Projects & Real-World Applications",
            "topics": [
              { "id": 1, "list": "Customer Analysis Project" },
              { "id": 2, "list": "Market Basket Analysis" },
              { "id": 3, "list": "Sales & Revenue Forecasting" },
              { "id": 4, "list": "Building a Data Dashboard" }
            ]
          }
        ]
      },

      {
        "id": 7,
        "batch": "App Development Internship",
        "param": "app-development-internship",
        "cbatch": "App Development Training course ",
        "cparam": "app-development-training",
        "duration":'3-4 Months',
        'fees':'20 Thousand',
        "container": [
          {
            "id": 1,
            "name": "React Native Basics",
            "topics": [
              { "id": 1, "list": "Introduction to React Native" },
              { "id": 2, "list": "Setting Up Development Environment" },
              { "id": 3, "list": "JSX & Components in React Native" },
              { "id": 4, "list": "State & Props" }
            ]
          },
          {
            "id": 2,
            "name": "UI/UX Design in Mobile Apps",
            "topics": [
              { "id": 1, "list": "Flexbox Layout in React Native" },
              { "id": 2, "list": "Styling & Themes" },
              { "id": 3, "list": "Navigation with React Navigation" },
              { "id": 4, "list": "Animations & Gestures" }
            ]
          },
          {
            "id": 3,
            "name": "API Integration & Data Management",
            "topics": [
              { "id": 1, "list": "Fetching Data with Axios" },
              { "id": 2, "list": "State Management with Redux" },
              { "id": 3, "list": "Handling JSON Data" },
              { "id": 4, "list": "Authentication & Firebase Integration" }
            ]
          },
          {
            "id": 4,
            "name": "Projects & Portfolio Building",
            "topics": [
              { "id": 1, "list": "Building a To-Do List App" },
              { "id": 2, "list": "E-commerce App Basics" },
              { "id": 3, "list": "Social Media App UI" },
              { "id": 4, "list": "Publishing on App Stores" }
            ]
          }
        ]
      },

      {
        "id": 8,
        "batch": "Cyber Security Internship",
        "param": "cyber-security-internship",
        "cbatch": "Cyber Security Training course ",
        "cparam": "cyber-security-training",
        "duration":'3-4 Months',
        'fees':'25 Thousand',
        "container": [
          {
            "id": 1,
            "name": "Cybersecurity Fundamentals",
            "topics": [
              { "id": 1, "list": "Introduction to Cybersecurity" },
              { "id": 2, "list": "Types of Cyber Attacks" },
              { "id": 3, "list": "Cybersecurity Principles" },
              { "id": 4, "list": "Ethics & Legal Aspects" }
            ]
          },
          {
            "id": 2,
            "name": "Network Security",
            "topics": [
              { "id": 1, "list": "Understanding Firewalls" },
              { "id": 2, "list": "Virtual Private Networks (VPNs)" },
              { "id": 3, "list": "Intrusion Detection Systems (IDS)" },
              { "id": 4, "list": "Securing Wireless Networks" }
            ]
          },
          {
            "id": 3,
            "name": "Threat Analysis & Vulnerability Assessment",
            "topics": [
              { "id": 1, "list": "Identifying Threats" },
              { "id": 2, "list": "Vulnerability Scanning" },
              { "id": 3, "list": "Penetration Testing Basics" },
              { "id": 4, "list": "Malware Analysis" }
            ]
          },
          {
            "id": 4,
            "name": "Hands-on Projects & Cyber Defense",
            "topics": [
              { "id": 1, "list": "Simulating a Phishing Attack" },
              { "id": 2, "list": "Building a Secure Network" },
              { "id": 3, "list": "Creating a Firewall Policy" },
              { "id": 4, "list": "Incident Response Simulation" }
            ]
          }
        ]
      },

      {
        "id": 9,
        "batch": "PHP Development Internship",
        "param": "php-development-internship",
        "cbatch": "PHP Development Training Course",
        "cparam": "php-development-training",
        "duration": "2-3 Months",
        "fees": "15 Thousand",
        "container": [
          {
            "id": 1,
            "name": "PHP Basics and Control Structures",
            "topics": [
              { "id": 1, "list": "Introduction to PHP" },
              { "id": 2, "list": "Setting Up PHP Environment" },
              { "id": 3, "list": "Syntax and Variables" },
              { "id": 4, "list": "Data Types, Strings, and Arrays" },
              { "id": 5, "list": "Conditional Statements (if, else, switch)" },
              { "id": 6, "list": "Loops (for, while, foreach)" },
              { "id": 7, "list": "Defining and Using Functions" }
            ]
          },
          {
            "id": 2,
            "name": "Form Handling, Validation, and File Management",
            "topics": [
              { "id": 1, "list": "Creating and Submitting Forms" },
              { "id": 2, "list": "Form Validation Techniques" },
              { "id": 3, "list": "Handling GET and POST Data" },
              { "id": 4, "list": "File Upload Handling" },
              { "id": 5, "list": "Working with Files and Directories" },
              { "id": 6, "list": "File Permissions and Security" }
            ]
          },
          {
            "id": 3,
            "name": "PHP and Databases",
            "topics": [
              { "id": 1, "list": "Introduction to MySQL" },
              { "id": 2, "list": "Connecting PHP with MySQL" },
              { "id": 3, "list": "CRUD Operations with MySQL" },
              { "id": 4, "list": "Prepared Statements for Security" }
            ]
          },
          {
            "id": 4,
            "name": "Advanced PHP Techniques and Project Development",
            "topics": [
              { "id": 1, "list": "Using Cookies and Sessions" },
              { "id": 2, "list": "Error and Exception Handling" },
              { "id": 3, "list": "Introduction to Object-Oriented Programming" },
              { "id": 4, "list": "Building a User Authentication System" },
              { "id": 5, "list": "Developing a Simple CRUD Application" },
              { "id": 6, "list": "Deploying PHP Applications" }
            ]
          }
        ]
      },

      {
        "id": 10,
        "batch": "Python Internship",
        "param": "python-internship",
        "cbatch": "Python Training Course",
        "cparam": "python-training",
        "duration": "3-4 Months",
        "fees": "15 Thousand",
        "container": [
          {
            "id": 1,
            "name": "Python Basics",
            "topics": [
              { "id": 1, "list": "Introduction to Python and Installation" },
              { "id": 2, "list": "Data Types, Variables, and Operators" },
              { "id": 3, "list": "Control Structures: If Statements, Loops" },
              { "id": 4, "list": "Functions and Modules" }
            ]
          },
          {
            "id": 2,
            "name": "Data Structures and Algorithms",
            "topics": [
              { "id": 1, "list": "Lists, Tuples, Sets, and Dictionaries" },
              { "id": 2, "list": "String Manipulation" },
              { "id": 3, "list": "Basic Algorithms and Sorting" },
              { "id": 4, "list": "Recursion and Problem Solving" }
            ]
          },
          {
            "id": 3,
            "name": "Object-Oriented Programming",
            "topics": [
              { "id": 1, "list": "Classes and Objects" },
              { "id": 2, "list": "Inheritance and Polymorphism" },
              { "id": 3, "list": "Encapsulation and Abstraction" },
              { "id": 4, "list": "Error Handling and Exceptions" }
            ]
          },
          {
            "id": 4,
            "name": "File Handling and Data Processing",
            "topics": [
              { "id": 1, "list": "Reading and Writing Files" },
              { "id": 2, "list": "CSV and JSON Data Handling" },
              { "id": 3, "list": "Data Processing and Analysis" },
              { "id": 4, "list": "Working with APIs and Web Data" }
            ]
          },
          {
            "id": 5,
            "name": "Projects",
            "topics": [
              { "id": 1, "list": "Build a Personal Budget Tracker" },
              { "id": 2, "list": "Develop a Web Scraper for Data Collection" },
              { "id": 3, "list": "Create a Simple Web Application using Flask" },
              { "id": 4, "list": "Implement a Basic Machine Learning Model" }
            ]
          }
        ]
      },

      {
        "id": 20,
        "batch": "AI/ML Internship",
        "param": "ai-ml-internship",
        "cbatch": "AI/ML Training Course",
        "cparam": "ai-ml-training",
        "duration": "4-6 Months",
        "fees": "25 Thousand",
        "container": [
          {
            "id": 1,
            "name": "Python for AI/ML",
            "topics": [
              { "id": 1, "list": "Python Basics for Data Science" },
              { "id": 2, "list": "NumPy, Pandas, and Matplotlib" },
              { "id": 3, "list": "Data Preprocessing and Cleaning" },
              { "id": 4, "list": "Introduction to Scikit-learn" }
            ]
          },
          {
            "id": 2,
            "name": "Machine Learning Basics",
            "topics": [
              { "id": 1, "list": "Supervised vs Unsupervised Learning" },
              { "id": 2, "list": "Linear Regression and Logistic Regression" },
              { "id": 3, "list": "Decision Trees and Random Forests" },
              { "id": 4, "list": "Model Evaluation and Validation" }
            ]
          },
          {
            "id": 3,
            "name": "Deep Learning",
            "topics": [
              { "id": 1, "list": "Introduction to Neural Networks" },
              { "id": 2, "list": "Building Models with TensorFlow and Keras" },
              { "id": 3, "list": "Convolutional Neural Networks (CNNs)" },
              { "id": 4, "list": "Recurrent Neural Networks (RNNs)" }
            ]
          },
          {
            "id": 4,
            "name": "Natural Language Processing",
            "topics": [
              { "id": 1, "list": "Text Preprocessing and Tokenization" },
              { "id": 2, "list": "Sentiment Analysis with NLP" },
              { "id": 3, "list": "Building Chatbots with Python" },
              { "id": 4, "list": "Transformers and BERT Models" }
            ]
          },
          {
            "id": 5,
            "name": "Projects",
            "topics": [
              { "id": 1, "list": "Build a Recommendation System" },
              { "id": 2, "list": "Create a Sentiment Analysis Tool" },
              { "id": 3, "list": "Develop an Image Classifier using CNN" },
              { "id": 4, "list": "Implement a Time-Series Prediction Model" }
            ]
          }
        ]
      }
      


      
      
      

]
export default Data