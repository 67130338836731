import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { TbArrowBigUpLinesFilled } from "react-icons/tb";

// Components
import Home from "./components/Home/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Newsletter from "./components/Footer/Newsletter/Newsletter";
import Bottom from "./components/Footer/Bottom/Bottom";
import Contact from "./components/Header/Contact/Contact";
import Service from "./components/Header/Service/Service";
import Student from "./components/Header/Students/Student";
import Load from "./components/Loader/Load";
import WebBatch from "./components/WebBatch/WebBatch";
import CodeMaster from "./components/CodeEditor/CodeMaster";
import CertiVerification from "./components/CertiVerification/CertiVerification";
import CertiDetails from "./components/CertiVerification/CertiDetails/CertiDetails";
import Register from "./components/Register/Register";
import Report from "./components/Footer/GtuReport/Report";
import ViewReport from "./components/Footer/GtuReport/ViewReport";
import PortFolio from "./components/Home/PortFolio/PortFolio";
import MyLoad from "./components/Loader/MyLoad";
import Success from "./Common/Success/Success";
import Search from "./components/Header/Search/Search";
import Left from "./components/LeftSideBar/Left";
import Right from "./components/Right/Right";
import SqlContent from "./components/Lerning/Sql/SqlContent/SqlContent";
import Terms from "./components/Footer/Tearm/Tearm";
import JoinForm from "./components/WebBatch/JoinForm/JoinForm";
import Confirm from "./components/WebBatch/JoinForm/Confirm/Confirm";
import NoPage from "./components/NoPage/NoPage";
import Tools from "./components/Footer/Tools/Tools";
import Fullstack from "./components/Header/Service/Course/Programs/Fullstack/Fullstack";
import Product from "./components/Header/Service/Course/Jason";

// Context Provider
import MyContextProvider from "./Uttis/MyContextProvider";

// Additional Utilities
import ScrollToTop from "react-scroll-to-top";
import GoToTop from "./components/GoToTop/GoToTop";

const App = () => {
  
  return (
    <BrowserRouter>
      <MyContextProvider>
      <Helmet>
      <title>V-Ex Tech Solution - IT Internships & Training in Vadodara</title>
      <meta name="title" content="Top IT Courses and Internships in Vadodara - V-Ex Tech Solution" />
      <meta
        name="description"
        content="V-Ex Tech Solution offers premier IT training and internship programs in Vadodara, focusing on real-world skills for careers in software development, web and mobile app development, and data science. Trusted by students and businesses alike, we specialize in practical, hands-on training that prepares students for industry roles and helps companies discover qualified talent. Explore our programs today to gain essential technical skills with a trusted partner in Gujarat's tech education."
      />
    </Helmet>
    

        <Load />
        <MyLoad />
        <Success />
        <Search />
      
        <Left />
        <Right />
        <JoinForm />
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/it/:batch" element={<WebBatch />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="*" element={<NoPage />} />
          <Route path="/terms-condition" element={<Terms />} />
          <Route path="/certi-verify" element={<CertiVerification />} />
          <Route path="/certi-details" element={<CertiDetails />} />
          <Route path="/vxeditor" element={<CodeMaster />} />
          <Route path="/student-registration" element={<Register />} />
          <Route path="/tools" element={<Tools />} />
          <Route path="/service" element={<Service Product={Product} />} />
          <Route path="/course/:coursename" element={<Fullstack Product={Product} />} />
          <Route path="/learning/:course/:title" element={<SqlContent />} />
          <Route path="/internship-report/:program" element={<ViewReport />} />
          <Route path="/about" element={<Student />} />
         
          <Route path="/internship-report" element={<Report />} />
          <Route path="/portfolio" element={<PortFolio />} />
        </Routes>

        <Newsletter />
        <Footer />
        <Bottom />

        <ScrollToTop
          smooth
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9,
          }}
          component={<TbArrowBigUpLinesFilled size={29} />}
        />
        <GoToTop />
      </MyContextProvider>
    </BrowserRouter>
  );
};

export default App;
